import { useEffect, useRef, useState } from "react"
import { Step } from "./Step"
import { CustomChart } from "../../components/Chart/Chart"
import { competition } from "../../services/resultsService";

export const ResultsCompetition = () => {
    const chartRef = useRef(null);
    const [content, setContent] = useState({
        competition: {
            competitionAttributes: ['Value 1', 'Value 2', 'Value 3', 'Value 4'],
            competition: [
                {
                    competitionName: 'Your Company Now',
                    position: { x: 3, y: 0 }
                },
                {
                    competitionName: 'Your Aim',
                    position: { x: 4, y: 5 }
                }
            ]
        },
        paragraph: 'Content is being loaded'
    });

    console.log(content.competition, "1");

    const [data, setData] = useState({
        labels: [...content?.competition.competition.map(el => (el.competitionName))],
        datasets: [
          {
            backgroundColor: ["white", "#FCE816", "transparent"],
            borderColor: ["white", "transparent", "white"],
            data: [...content?.competition.competition.map(el => (el.position))],
          },
        ],
      })

    const [companyName, setCompanyName] = useState("Your Company");

    const header = {
        index: "08",
        section: "Relationships - Competition",
        company: companyName
    }

    useEffect(() => {
        competition().then(response => {
            const {competition, company} = response.data;
            if(competition) {
                console.log(competition);
                // setContent({...content, competition: JSON.parse(competition)});
                console.log(content);
            }
            company ? setCompanyName(company) : setCompanyName('Your Company');
        }).catch(err => {
            console.log(err);
        })
    }, []);
    return (
        <Step header={header}>
            <CustomChart ref={chartRef} data={data}/>
            <p className="results__description">
                {content.paragraph}
            </p>
        </Step>
    )
}