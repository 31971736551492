import { useState } from "react"
import { useEffect } from "react";
import { getCompleted, getCompletedPayment, isAuthenticated } from "../../services";
import { Outlet, useNavigate } from "react-router-dom";
import { CustomDialog } from "../../components/Dialog/CustomDialog";
export const Results = () => {
    const navigate = useNavigate();
    const [hasCompleted, setHasCompleted] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    // useEffect(() => {
    //     isAuthenticated().then(response => {
    //         const data = response.data;
    //         if (data && data === 1) {
    //             getCompleted().then(response => {
    //                 const data = response.data;
    //                 if (data === 0) {
    //                     setHasCompleted(false);
    //                     // setIsOpen(true);
    //                 } else {
    //                     setHasCompleted(true);
    //                     setIsOpen(false);
    //                     navigate("/results/purpose")
    //                 }
    //             }).catch(err => {
    //                 console.log(err);
    //             })
    //         } else {
    //             isAuthenticated();
    //         }
    //     }).catch(err => {
    //         console.error(err);
    //     })
    // }, []);

    const handleClose = () => {

    }

    // if (hasCompleted) {
    //     return (<Outlet />)
    // } else {
    //     return (
    //         <CustomDialog open={false} onClose={handleClose} />
    //     )
    // }

    return <Outlet />
}