import { Field, FieldArray } from "formik";

export const StepFive = (props) => {
  const handleArticleClassName = () => {
    if (props.isFilled) return "form__container isFilled exit";
    if (props.enteringStep) return "form__container enter";
    else return "form__container";
  }
  if (props.isActive) {
    return (
      <article className={handleArticleClassName()}>
        <div className="form__wrapper">
          <p className="form__description">
            List brands or celebrities that look like that
          </p>
          <fieldset className="flex-col">
            <FieldArray name="companiesThatLookLikeThat2" {...props}>
              <div>
                {props.initialValues.companiesThatLookLikeThat2.length > 0 &&
                  props.initialValues.companiesThatLookLikeThat2.map((companyThatLookLikeThat1, index) => (
                    <Field
                      name={`companiesThatLookLikeThat2.${index}.companyThatLookLikeThat1`}
                      placeholder="Company"
                      className="has-border-bottom"
                      key={index}
                    />
                  ))
                }
              </div>
            </FieldArray>
          </fieldset>
        </div>
      </article>
    );
  }
};
